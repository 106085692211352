import { Outlet } from '@remix-run/react'
import robotLikesImage from '#app/assets/images/robot-likes.jpeg'

export default function AuthLayout() {
	const defaultimage = robotLikesImage

	return (
		<div className="flex h-screen w-screen justify-center">
			<div className="hidden flex-1 border-r-2 md:block">
				<img src={defaultimage} className="h-full w-full object-cover" />
			</div>
			<div className="max-w-lg flex-1 overflow-y-auto">
				<div className="min-h-screen flex-col justify-center p-8 sm:p-14">
					<Outlet />
				</div>
			</div>
		</div>
	)
}
